

















import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DialogoDeTurnoEncerrado extends Vue {

	visivel = false

	mostrar(){
		this.visivel = true
	}

	recarregar() {
		window.location.reload()
		window.location.href = '/caixa'
		localStorage.removeItem('TURNO_DE_VENDA_ENCERRADO')
		localStorage.removeItem('VENDA_ATUAL_STORAGE')
		localStorage.removeItem('TURNO_DE_VENDA_STORAGE')
		localStorage.removeItem('TELA_DE_IMPRESSAO_DE_ROMANEIO')
	}
}
